import axios from "axios";
// const apiDomain = "https://mia-api.rlogical.com/admin/api/v1";
let apiDomain = "";
let apiDomainApp = "";
// const domain = "https://mia-api-dev.rlogical.com"
// const apiDomain = "http://localhost:3000/admin/api/v1";
// https://mia.rlogical.com/admin/api/v1/login

const location = window.location.origin;

if (location.includes('dev') || location.includes('localhost')) {
  apiDomain = "https://mia-api.rlogical.com/admin/api/v1"
  apiDomainApp = "https://mia-api.rlogical.com/app/api/v1"
  // apiDomain = "https://mia-api-dev.rlogical.com/admin/api/v1"
  // apiDomain = "http://localhost:8090/admin/api/v1"
  // apiDomainApp = "http://localhost:8090/app/api/v1"
} else {
  apiDomain = "https://mia-api.rlogical.com/admin/api/v1"
  apiDomainApp = "https://mia-api.rlogical.com/app/api/v1"
  // apiDomain = "http://localhost:8090/admin/api/v1"
  // apiDomain = "http://localhost:8090/admin/api/v1"
  // apiDomainApp = "http://localhost:8090/app/api/v1"
}


const getHeaders = () => {
  const token = localStorage.getItem("token");
  // console.log("token::getHeaders", token);
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "No-Auth-Challenge": true,
      "Accept-Language": 'en'
    },
  };
};

const mias = {
  apis: {
    auth: {
      login: (credential) => axios.post(`${apiDomain}/login`, credential),
      getUser: () => axios.get(`${apiDomain}/validateUser`, getHeaders()),
      forgotPassword: (data) =>axios.post(`${apiDomain}/profile/forgotPassword`,data ),
      resetPassword: (data) => axios.post(`${apiDomain}/profile/resetPassword`, data),
      resetPasswordApp: (data) => axios.post(`${apiDomainApp}/profile/resetPassword`, data),
    },
    dashboard: {
      getCounts: () => axios.get(`${apiDomain}/dashboard/gets`, getHeaders()),
    },
    admin: {
      getAdminList: (pageIndex, pageSize, searchKey) => {
        const config = getHeaders();
        config.params = {
          page: pageIndex,
          pageLength: pageSize,
          searchKey: searchKey,
        };
        return axios.get(
          `${apiDomain}/admin-user/getAllList-pagination`,
          config
        );
      },
      createAdmin: (data) =>
        axios.post(`${apiDomain}/admin-user/create`, data, getHeaders()),
      getAdminUserById: (id) => {
        return axios.post(
          `${apiDomain}/admin-user/getOne`,
          { id: id },
          getHeaders()
        );
      },
      updateAdminUser: (data) => {
        return axios.post(`${apiDomain}/admin-user/update`, data, getHeaders());
      },
    },
    users: {
      userList: (pageIndex, pageSize, searchKey) => {
        const config = getHeaders();
        config.params = {
          page: pageIndex,
          pageLength: pageSize,
          searchKey: searchKey,
        };
        return axios.get(`${apiDomain}/app-user/getList`, config);
      },
      
      getAppUserById: (id) => axios.post(`${apiDomain}/app-user/getOne`, { id: id }, getHeaders()),
      getHoodListByUserId: (userId) => {
        const config=getHeaders();
        config.params={
          userId:userId
        }
        return axios.get(`${apiDomain}/hoods/hoodListByUserId`, config)
      },
      getPlacesListByUserId: (userId) => {
        const config=getHeaders();
        config.params={
          userId:userId
        }
        return axios.get(`${apiDomain}/places/placeListByUserId`, config)
        },
      updateAppUser: (data) => {
        return axios.post(`${apiDomain}/profile/updateProfileV3`, data, getHeaders());
      },
      deleteAppUser: (id) => {
        const config = getHeaders();
        config.params = {
          id: id,
        };
        return axios.delete(`${apiDomain}/app-user/delete`, config);
       }
      },
    deletedUsers: {
      deletedUserList: (pageIndex, pageSize, searchKey) => {
        const config = getHeaders();
        config.params = {
          page: pageIndex,
          pageLength: pageSize,
          searchKey: searchKey,
        };
        return axios.get(`${apiDomain}/delete-app-user/getList`, config);
      },
    },  
    cms: {
      getCmsList: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/cms-page/getAllList`, config);
      },
      getCms: (id) => {
        return axios.post(
          `${apiDomain}/cms-page/getOne`,
          { id: id },
          getHeaders()
        );
      },
      saveCms: (cmsData) => {
        return axios.post(
          `${apiDomain}/cms-page/update`,
          cmsData,
          getHeaders()
        );
      },
    },
    language: {
      getLanguageList: () => {
        const config = getHeaders();
        // config.params = { page: 1, limit: 10 };
        return axios.get(`${apiDomain}/language/getList`, config);
      },
    },
    country: {
      getCountryList: (pageIndex, pageSize, searchKey) => {
        const config = getHeaders();
        config.params = {
          page: pageIndex,
          pageLength: pageSize,
          searchKey: searchKey,
        };
        return axios.get(`${apiDomain}/country/getList-filter`, config);
      },
      saveCountry: (countryData) => {
        return axios.post(
          `${apiDomain}/country/create`,
          countryData,
          getHeaders()
        );
      },
      getCountryById: (id) => {
        return axios.post(
          `${apiDomain}/country/getOne`,
          { id: id },
          getHeaders()
        );
      },
    },
    posts:{
      getPostList: (pageIndex, pageSize, searchKey) => {
        const config = getHeaders();
        config.params = {
          page: pageIndex,
          pageLength: pageSize,
          searchKey: searchKey,
        };
        return axios.get(`${apiDomain}/posts/getAllList`, config);
      },
      getPostById: (id) => {
        return axios.post(
          `${apiDomain}/posts/getOne`,
          { id: id },
          getHeaders()
        );
      },
      getCategoryList: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/posts/tag/getAllList`, config);
      },
      updatePosts: (data) => {
        return axios.post(`${apiDomain}/posts/update`, data, getHeaders());
      },
      deletePostsById: (id) => {
        return axios.delete(`${apiDomain}/posts/delete/${id}`)
      },
      deletePostsCommentById: (id) => {
        return axios.delete(`${apiDomain}/posts/deleteComment/${id}`)
      }
    },
    hoods: {
      getHoodCategoryList: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/hoods/category/getAllList`, config);
      },
      getHoodCategoryListForDropdown: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/hoods/category/getAllListForDropdown`, config);
      },
      getHoodRulesList: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/hoods/rules/getAllList`, config);
      },
      getHoodCategoryById: (id) => {
        return axios.post(
          `${apiDomain}/hoods/category/getList`,
          { id: id },
          getHeaders()
        );
      },
      getHoodRuleById: (id) => {
        return axios.post(
          `${apiDomain}/hoods/rules/getList`,
          { id: id },
          getHeaders()
        );
      },
      updateHoodCategory: (data) => {
        return axios.post(
          `${apiDomain}/hoods/category/update`,
          data,
          getHeaders()
        );
      },
      updateHoodRule: (data) => {
        return axios.post(
          `${apiDomain}/hoods/rules/update`,
          data,
          getHeaders()
        );
      },
      getHoodTagCategoryList: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/hoods/tag-category/getAllList`, config);
      },
      getHoodTagList: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/hoods/tag/getAllList`, config);
      },
      getHoodTagCategoryById: (id) => {
        return axios.post(
          `${apiDomain}/hoods/tag-category/getList`,
          { id: id },
          getHeaders()
        );
      },
      getHoodTagById: (id) => {
        return axios.post(
          `${apiDomain}/hoods/tag/getList`,
          { id: id },
          getHeaders()
        );
      },
      updateHoodTagCategory: (data) => {
        return axios.post(
          `${apiDomain}/hoods/tag-category/update`,
          data,
          getHeaders()
        );
      },
      updateHoodTag: (data) => {
        return axios.post(`${apiDomain}/hoods/tag/update`, data, getHeaders());
      },
      createHoodCategory: (data) => {
        return axios.post(
          `${apiDomain}/hoods/category/create`,
          data,
          getHeaders()
        );
      },
      createHoodRule: (data) => {
        return axios.post(
          `${apiDomain}/hoods/rules/create`,
          data,
          getHeaders()
        );
      },
      createHoodTagCategory: (data) => {
        return axios.post(
          `${apiDomain}/hoods/tag-category/create`,
          data,
          getHeaders()
        );
      },
      createHoodTag: (data) => {
        return axios.post(`${apiDomain}/hoods/tag/create`, data, getHeaders());
      },
      updateHoodCategoryStatus: (data) => {
        return axios.post(
          `${apiDomain}/hoods/category/update-status`,
          data,
          getHeaders()
        );
      },
      updateHoodRuleStatus: (data) => {
        return axios.post(
          `${apiDomain}/hoods/rules/update-status`,
          data,
          getHeaders()
        );
      },
      updateHoodTagCategoryStatus: (data) => {
        return axios.post(
          `${apiDomain}/hoods/tag-category/update-status`,
          data,
          getHeaders()
        );
      },
      updateHoodTagStatus: (data) => {
        return axios.post(
          `${apiDomain}/hoods/tag/update-status`,
          data,
          getHeaders()
        );
      },
      deleteHoodCategory: (data) => {
        return axios.post(
          `${apiDomain}/hoods/category/delete`,
          data,
          getHeaders()
        );
      },
      deleteHoodRule: (data) => {
        return axios.post(
          `${apiDomain}/hoods/rules/delete`,
          data,
          getHeaders()
        );
      },
      deleteHoodTagCategory: (data) => {
        return axios.post(
          `${apiDomain}/hoods/tag-category/delete`,
          data,
          getHeaders()
        );
      },
      deleteHoodTag: (data) => {
        return axios.post(`${apiDomain}/hoods/tag/delete`, data, getHeaders());
      },
      getHoodsList: (pageIndex, pageSize, searchKey,categoryCode) => {
        const config = getHeaders();
        config.params = {
          page: pageIndex,
          pageLength: pageSize,
          isActive: 1,
          searchKey: searchKey,
          categoryCode:categoryCode,
        };
        return axios.get(`${apiDomain}/hoods/getAllList`, config);
      },
      getHoodById: (id) => {
        return axios.post(
          `${apiDomain}/hoods/getHoodDetails`,
          { id: id },
          getHeaders()
        );
      },
      getPostsListHoodById: (data) => {
        return axios.post(
          `${apiDomain}/hoods/getHoodPostList`,
          data,
          getHeaders()
        );
      },
      updateHood: (updateObj) => axios.post(`${apiDomain}/hoods/updateHoodList`, updateObj, getHeaders())
    },
    places: {
      getPlaceCategoryList: () => {
        const config = getHeaders();
        return axios.get(
          `${apiDomain}/discover/category/getMainCategoryList`,
          config
        );
      },
      getPlaceCategoryListForDropdown: () => {
        const config = getHeaders();
        return axios.get(
          `${apiDomain}/discover/category/getMainCategoryListForDropdown`,
          config
        );
      },
      getPlaceTagCategoryList: () => {
        const config = getHeaders();
        return axios.get(
          `${apiDomain}/discover/category/getSubcategoryList`,
          config
        );
      },

      getPlaceCategoryById: (id) => {
        return axios.post(
          `${apiDomain}/discover/category/getList`,
          { id: id },
          getHeaders()
        );
      },
      getPlaceCategoryByIdForDropdown: (id) => {
        return axios.post(
          `${apiDomain}/discover/category/getListForSubCategoryDropdown`,
          { id: id },
          getHeaders()
        );
      },
      getPlaceTagCategoryById: (categoryId, subCategoryId) => {
        return axios.post(
          `${apiDomain}/discover/category/get/oneSubCategory`,
          { categoryId, subCategoryId },
          getHeaders()
        );
      },
      createPlaceTag: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/add/subCategory`,
          data,
          getHeaders()
        );
      },

      updatePlaceCategory: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/update`,
          data,
          getHeaders()
        );
      },
      updatePlaceTagCategory: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/update/subCategory`,
          data,
          getHeaders()
        );
      },
      updatePlaceTagCategoryStatus: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/update/subCategoryStatus`,
          data,
          getHeaders()
        );
      },
      getPlaceTagList: () => {
        const config = getHeaders();
        return axios.get(
          `${apiDomain}/discover/category/getMainCategoryList`,
          config
        );
      },
      deletePlaceTagCategory: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/delete/subCategory`,
          data,
          getHeaders()
        );
      },
      createPlaceCategory: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/create`,
          data,
          getHeaders()
        );
      },
      createPlaceTagCategory: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/add/subCategory`,
          data,
          getHeaders()
        );
      },
      updatePlaceCategoryStatus: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/update/categoryStatus`,
          data,
          getHeaders()
        );
      },

      deletePlaceCategory: (data) => {
        return axios.post(
          `${apiDomain}/discover/category/delete/category`,
          data,
          getHeaders()
        );
      },
      getAllPlaceList: (pageIndex, pageSize, searchKey,primaryCode) => {
        const config = getHeaders()
        config.params = {
          page: pageIndex,
          pageLength: pageSize,
          searchKey: searchKey,
          primaryCode:primaryCode
        };
        return axios.get(`${apiDomain}/places/getAllList`, config);
      },
      getPlaceDetails:(placeId) => axios.post(`${apiDomain}/places/getOne`, {id:placeId}, getHeaders()),
      updatePlaceDetails:(placeObj) => axios.post(`${apiDomain}/places/update`, placeObj, getHeaders())
    },
    labelLanguage: {
      getLabelLanguageList: (pageIndex, pageSize, searchKey) => {
        const config = getHeaders();
        config.params = { page: pageIndex, pageLength: pageSize, searchKey: searchKey };
        return axios.get(
          `${apiDomain}/labelLanguage/getList`,
          config
        );
      },

      getLabelLanguageById: (key) => {
        return axios.post(
          `${apiDomain}/labelLanguage/getOne`,
          { key: key },
          getHeaders()
        );
      },
      createLabelLanguage: (data) => {
        return axios.post(
          `${apiDomain}/labelLanguage/create`,
          data,
          getHeaders()
        );
      },

      updateLabelLanguage: (data) => {
        return axios.post(
          `${apiDomain}/labelLanguage/update`,
          data,
          getHeaders()
        );
      },

      updateLabelLanguageStatus: (data) => {
        return axios.post(
          `${apiDomain}/labelLanguage/update/labelLanguageStatus`,
          data,
          getHeaders()
        );
      },

      deleteLabelLanguage: (data) => {
        return axios.post(
          `${apiDomain}/labelLanguage/delete`,
          data,
          getHeaders()
        );
      },
    },
    roles: {
      getRolesList: () => {
        const config = getHeaders();
        return axios.get(`${apiDomain}/role/getAllList`, config);
      },
      getRoleById: (id) => {
        return axios.post(
          `${apiDomain}/role/getList`,
          { id: id },
          getHeaders()
        );
      },
    },
    miaInterest: {
      getMiaInterestList: () => {
        return axios.get(`${apiDomain}/miaInterest/getAllList`, getHeaders());
      },
      updateMiaInterestStatus: (data) => {
        return axios.post(
          `${apiDomain}/miaInterest/updateStatus`,
          data,
          getHeaders()
        );
      },
      updateMiaInterest: (data) => {
        return axios.post(
          `${apiDomain}/miaInterest/update`,
          data,
          getHeaders()
        );
      },
      addMiaInterest: (data) => {
        return axios.post(
          `${apiDomain}/miaInterest/create`,
          data,
          getHeaders()
        );
      },
      deleteMiaInterest: (data) => {
        return axios.post(
          `${apiDomain}/miaInterest/delete`,
          data,
          getHeaders()
        );
      },
      getMiaInterestById: (id) => {
        return axios.post(
          `${apiDomain}/miaInterest/getOne`,
          { id: id },
          getHeaders()
        );
      },
    },
    miaBestfits: {
      getMiaBestfitsList: () => {
        return axios.get(`${apiDomain}/miasBestFit/getAllList`, getHeaders());
      },
      updateMiaBestfitStatus: (data) => {
        return axios.post(
          `${apiDomain}/miasBestFit/updateStatus`,
          data,
          getHeaders()
        );
      },
      addMiaBestfit: (data) => {
        return axios.post(
          `${apiDomain}/miasBestFit/create`,
          data,
          getHeaders()
        );
      },
      getMiaBestfitById: (id) => {
        return axios.post(
          `${apiDomain}/miasBestFit/getOne`,
          { id: id },
          getHeaders()
        );
      },
      deleteMiaBestfit: (data) => {
        return axios.post(
          `${apiDomain}/miasBestFit/delete`,
          data,
          getHeaders()
        );
      },
      updateMiaBestfit: (data) => {
        return axios.post(
          `${apiDomain}/miasBestFit/update`,
          data,
          getHeaders()
        );
      },
    },
  },
};

export default mias;
